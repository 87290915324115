import { graphql, PageProps } from "gatsby"
import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { css } from "@emotion/react"

import Layout from "../templates/layout"
import Seo from "../templates/seo"
import BlogPostList from "../components/organisms/blog-post-list"
import Sidebar from "../components/organisms/sidebar"
import Card from "../components/atoms/card"
import {
  l_page_container,
  l_page_content
} from "../components/atoms/__layout"

const CategoryPage: React.FC<PageProps<Queries.CategoryPagequeryQuery>> = ({
  data,
  pageContext,
}) => {
  const { tag } = pageContext
  const postdata = data?.allMarkdownRemark?.nodes
  const siteTitle = data?.site?.siteMetadata?.title
  const breakpoints = useBreakpoint()
  const name = data.site?.siteMetadata?.author?.name
  const description = data?.site?.siteMetadata?.description

  return (
    <Layout>
      <Seo title="category" />
      <div css={l_page_container}>
        <section css={l_page_content}>
          <Card ><p css={link_title}>タグ: {tag} ({data?.allMarkdownRemark?.totalCount}件)</p></Card>
          <BlogPostList postsdata={postdata} />
        </section>
        {breakpoints.pc ? <Sidebar toc={false} author={name} description={description} /> : <div />}
      </div>
    </Layout >
  )
}

export default CategoryPage

export const Pagequery = graphql`
  query CategoryPagequery($tag: String) {
    site {
      siteMetadata {
        title
        description
        author {
          name
        }
      }
    }
    allMarkdownRemark(
      limit: 30
      sort: { order: DESC, fields: frontmatter___date }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      nodes {
        frontmatter {
          tags
          title
          date(formatString: "YYYY.MM.DD")
        }
        fields {
          slug
        }
      }
    }
  }
`
const link_title = css`
  color: black;
  text-decoration: none;
  font-size: 28px;
  padding: 10px;
  justify-content: center;
  display: flex;
  font-weight: 700;
  margin: 0;
  `